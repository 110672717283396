.flex {
    display: flex;
}

.flex.justify-center {
    justify-content: center;
}

.flex.align-center {
    align-items: center;
}

.flex.space-between {
    justify-content: space-between;
}

.flex.flex-center {
    justify-content: center;
    align-items: center;
}