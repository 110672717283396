.users-info {
    padding: 10px 20px;
    background-color: $app-clr;
    position: fixed;
    left: 0;
    top: 0;
    // transform: translateY(50%);
    z-index: 20;
    bottom: 50%;
    display: flex;
    flex-direction: column;
    box-shadow: 0;
    height: fit-content;
    transform: translateX(-100%);

    &.open {
        transform: translateX(0);
        box-shadow: 0px -1px 17px 1px;
    }

    button {
        svg {
            color: $pri-color;
        }

        width: 30px;
        height: 30px;
        margin-left: 10px;
        margin-top: -5px;
        fill: green;
        background-color: unset;
        position: absolute;
        left: 0;
        border: none;
        border-radius: 50%;

        &:hover,
        &:active {
            background-color: $hover-clr;
        }

        transition: all ease 0.2s;
    }

    h1 {
        text-decoration: underline;
    }


    p {
        display: flex;
        flex-direction: column;
        font-size: larger;

        .user-details {
            margin-bottom: 1rem;
            font-weight: bold;

            span {
                font-weight: 400;
            }
        }
    }

    .scores-wrapper {
        overflow-y: visible;

        h2 {
            margin: 0;
            margin-bottom: 1rem;
            text-align: center;
            text-decoration: underline;
        }

        .scores {
            display: grid;
            grid-template-rows: repeat(4, 1fr);
            grid-gap: 10px;


            li {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                background-color: white;

                span {
                    text-align: center;
                }
            }
        }
    }

    transition: all ease 0.5s;

    @include for-mobile-layout {
        height: 100%;
        width: 100%;
    }

    @include for-narrow-layout {
        width: 500px;
    }
}