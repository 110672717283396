// LAYOUT
$layoutPadding: 20px;

// LAYOUT BREAKPOINTS
$break-narrow: 600px;
$break-normal: 800px;
$break-wide: 1100px;

$padded-break-narrow: $break-narrow + $layoutPadding * 2;
$padded-break-normal: $break-normal + $layoutPadding * 2;
$padded-break-wide: $break-wide + $layoutPadding * 2;

// COLORS 
$pri-color: #013553;
$secondary-color: #1693db;
$decoration-color: #F0E8E6;
$shadow-clr: #83838355;
$app-clr: #F7FBFF;
$faded-pri-clr: #335d75;
$hover-clr: #EEEEF0;