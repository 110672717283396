header {
    display: flex;
    justify-content: space-between;
    height: rem(80px);

    @include for-narrow-layout {
        height: 15vh;

    }

    background-color: #013553;
    display: flex;
    z-index: 1;

    img {
        height: 100%;
        cursor: pointer;
    }

    nav {
        position: fixed;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: rem(150px);
        background-color: $pri-color;
        z-index: 10;

        &.rtl {
            transform: translateX(-100%);
            left: 0;
        }

        &.ltr {
            transform: translateX(100%);
            right: 0;
        }

        &.open.rtl,
        &.open.ltr {
            transform: translateX(0);
        }

        @include for-wide-layout {
            &.rtl {
                transform: translateX(0);
                left: 0;
            }

            &.ltr {
                transform: translateX(0);
                right: 0;
            }
        }

        a {
            color: white;
            width: fit-content;
            margin: 0 10px;
            word-break: keep-all;
            height: rem(50px);
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                color: #DFD0BB;
            }

            transition: all 0.2s ease;
        }

        transition: all 0.5s ease;
    }

    .menu-sign {
        padding-left: rem(20px);
        color: white;
        font-size: 30px;
        height: 100%;
        background-color: unset;
        border: none;

    }

    .screen {
        inset: 0;
        background-color: unset;
        position: fixed;
        z-index: -10;
        transition: background-color 0.5s ease;

        &.open {
            z-index: 5;
            background-color: darken($shadow-clr, 70%);
        }
    }

    @include for-wide-layout {
        .menu-sign {
            display: none;
        }

        nav {
            justify-content: flex-end;
            position: static;
            width: 870px;
            flex-direction: row;
            background-color: unset;
            transform: translateX(0);
        }

        .screen {
            display: none;
        }
    }

    button {
        @include button
    }
}