.interactive-section {
    grid-column: 2;
    position: relative;
    width: 100%;
    margin-top: rem(80px);

    .progress-bar {
        grid-column: 1/-1;
        position: sticky;
        top: 0;
        left: 0;

        .progress-bar-wrapper {

            width: 100%;

            border-bottom-right-radius: 30px;
            display: flex;
            flex-direction: column;
            position: absolute;
            left: 0;
            top: 0;

            h1 {
                margin-top: rem(20px);
                margin-right: rem(10px);
            }
        }

    }

    .question-list {
        width: 100%;
        grid-column: 1/-1;
        margin-top: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include wide-layout {
            grid-column: 2;
        }

        .question-preview {
            background-color: rgb(219, 227, 234);
            width: 100%;
            text-align: center;
            padding: rem(30px) rem(15px);
            margin: rem(20px) 0;

            @include mobile-layout {
                h1 {
                    font-size: 14px;
                }
            }

            .answers-wrapper {
                display: flex;
                text-align: center;
                justify-content: center;
                padding-top: rem(20px);

                @include wide-layout {
                    padding-top: rem(20px);

                }

                .answer-wrapper {
                    width: 100px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 0 rem(3px);
                    justify-content: flex-start;

                    .radio-button {
                        width: rem(30px);
                        height: rem(30px);
                        cursor: pointer;
                        border: 1px solid $secondary-color;

                        &:hover {
                            background-color: $pri-color ;
                        }

                        &.picked {
                            background-color: $pri-color ;
                        }

                        transition: all 200ms ease;

                    }
                }
            }
        }
    }

    .pages-button-wrapper {
        grid-column: 2;
    }
}