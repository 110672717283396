.form-wrapper {
    color: black;
    width: 100%;
    display: flex;

    form {
        padding: rem(20px) rem(10px);
        background-color: $app-clr;
        display: flex;
        flex-direction: column;
        grid-column: 2;

        @include for-narrow-layout {
            width: rem(600px);
        }

        h1 {
            margin-bottom: rem(30px);
        }

        label {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        input[type=text],
        input[type=email] {
            height: 30px;
            font-size: 16px;
            margin: rem(10px) 0;
        }

        button {
            @include for-narrow-layout {
                align-self: flex-end;
            }

            margin-top: rem(10px);
            @include button
        }
    }
}