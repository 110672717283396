@font-face {
    font-family: 'heebo';
    src: url(../../fonts/heebo/Heebo-Thin.ttf);
    font-weight: 100;
}

@font-face {
    font-family: 'heebo';
    src: url(../../fonts/heebo/Heebo-ExtraLight.ttf);
    font-weight: 200;
}

@font-face {
    font-family: 'heebo';
    src: url(../../fonts/heebo/Heebo-Light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: 'heebo';
    src: url(../../fonts/heebo/Heebo-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'heebo';
    src: url(../../fonts/heebo/Heebo-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'heebo';
    src: url(../../fonts/heebo/Heebo-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: 'heebo';
    src: url(../../fonts/heebo/Heebo-Bold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: 'heebo';
    src: url(../../fonts/heebo/Heebo-ExtraBold.ttf);
    font-weight: 800;
}

@font-face {
    font-family: 'heebo';
    src: url(../../fonts/heebo/Heebo-Black.ttf);
    font-weight: 900;
}