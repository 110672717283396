.user-msg {
    color: $decoration-color;
    padding: 10px;
    font-size: 1.4em;
    position: fixed;
    top: 0;
    z-index: 10;

    &.rtl {
        left: 0;
    }

    &.ltr {
        right: 0;
    }

    button {
        float: left;
        border: 0;
        border-radius: 50%;
        margin: 5px;
        background-color: $decoration-color;
    }

    &.user-msg.success {
        background-color: rgb(41, 189, 73);
    }

    &.user-msg.danger {
        background-color: rgb(216, 59, 59);

    }
}