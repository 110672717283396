.admin-page .log-in-form {
    display: flex;
    justify-content: center;

    .log-in-form-wrapper {
        background-color: $decoration-color;
        padding: rem(20px) rem(10px);
        display: flex;
        flex-direction: column;

        input[type=text],
        input[type=password] {
            height: rem(30px);
            width: 100%;
        }

        button {
            margin-top: rem(15px);
            @include button
        }
    }
}