.main-layout {
    display: grid;
    grid-template-columns: $layoutPadding 1fr $layoutPadding;

    >* {
        &:not(.full) {
            grid-column: 2;
        }
    }

    &>*.full {
        grid-column: 1 / -1;
    }

    >*.full {
        grid-auto-flow: dense;
        grid-template-columns: repeat(6, 1fr);

        grid-template-columns: $layoutPadding 1fr $layoutPadding;

        @include for-narrow-layout {
            @include layoutGTC($break-narrow);
        }

        @include for-normal-layout {
            @include layoutGTC($break-normal);
        }

        @include for-wide-layout {
            @include layoutGTC($break-wide);
        }
    }

    @include for-narrow-layout {
        @include layoutGTC($break-narrow);
    }

    @include for-normal-layout {
        @include layoutGTC($break-normal);
    }

    @include for-wide-layout {
        @include layoutGTC($break-wide);
    }
}