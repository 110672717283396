html {
    font-size: rem(14px);

    font-family: 'heebo';

    @include for-normal-layout {
        font-size: rem(16px);
    }
}

* {
    box-sizing: border-box;
}

body {
    color: $pri-color;
    font-family: 'heebo', sans-serif;
    margin: 0;
    line-height: rem(25px);
    background-color: $app-clr;
}

a {
    text-decoration: none;
}

button :hover {
    cursor: pointer;
}

main {
    grid-column: 2;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}