.hero {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    height: rem(220px);

    .text-wrapper {
        flex-grow: 1;
        width: auto;
        display: flex;
        justify-content: center;

        h1 {
            padding: 0 rem(10px);
            text-align: center;

            // width: 50%;
            display: inline-flex;
            flex-direction: column;
            color: $pri-color;
            font-size: rem(18px);
        }
    }

    .languages-wrapper {
        background-color: $app-clr;
        position: absolute;
        top: 0;
        display: flex;

        button {
            background-color: unset;
            border: none;
            width: rem(50px);
        }

        .languages {
            display: flex;
            gap: 10px;
            padding: 10px;

            li {
                cursor: pointer;
                width: 40px;
                height: 20px;
            }
        }

        &.rtl {
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
        }

        &.ltr {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
        }

        &.open.rtl,
        &.open.ltr {
            transform: translateX(0);
        }

        transition: all 0.5s ease;
    }

    @include for-mobile-layout() {
        .languages-wrapper {
            flex-direction: row-reverse;

            &.rtl {
                right: 0;
                transform: translateX(112px);

                button {
                    border-right: 1px solid $shadow-clr;
                }
            }

            &.ltr {
                transform: translateX(-112px);
                left: 0;

                button {
                    border-left: 1px solid $shadow-clr;
                }
            }
        }
    }

    @include for-narrow-layout() {
        overflow-x: hidden;

        .languages-wrapper {
            flex-direction: row;

            &.rtl {
                transform: translateX(-112px);
                right: unset;
                left: 0;
                border-bottom-right-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;

                button {
                    border-left: 1px solid $shadow-clr;
                }
            }

            &.ltr {
                transform: translateX(112px);
                left: unset;
                right: 0;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                border-bottom-left-radius: 10px;
                border-top-left-radius: 10px;

                button {
                    border-right: 1px solid $shadow-clr;
                }
            }
        }
    }

    .decoration {
        // width: 50%;
        height: 100%;

        .mati {
            // width: 145px;
            height: 100%;
            // padding-inline-end: rem(10px);
        }
    }

    @include for-narrow-layout {
        .text-wrapper {

            h1 {
                font-size: rem(26px);
            }
        }
    }

    .banner {
        height: 100%;
        position: absolute;
        z-index: -1;
        grid-column: 1/-1;
        width: 100%;
        object-fit: fill;
    }

    @include for-narrow-layout {
        .decoration {
            display: none;
        }
    }
}

@include for-narrow-layout {
    .hero {
        height: rem(300px);
    }
}

.intro {
    max-width: 100%;
    grid-column: 2;
    display: flex;
    line-height: rem(22px);
    justify-content: center;
    height: 57vh;
    margin-bottom: rem(20px);

    .intro-text {
        width: 100%;
        background-color: $decoration-color;
        height: fit-content;
        padding: rem(15px) rem(20px);
        display: flex;
        flex-direction: column;

        p {
            margin: 0;
            margin-bottom: 10px;
        }

        .good-luck {
            text-align: center
        }

        button {
            @include button;
            align-self: flex-end;
        }
    }

    .decoration {
        display: none
    }

    @include for-narrow-layout {
        margin: rem(50px) 0;
        justify-content: space-between;
        align-items: center;
        gap: rem(10px);
        grid-column: 1/-1;

        .intro-text {
            width: rem(365px);
            padding: rem(20px) rem(15px);

        }

        .decoration {
            width: rem(250px);
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            position: relative;

            .decoration-color {
                background-color: $pri-color;
                height: 380px;
                width: 150px;
            }

            img {
                width: 200px;
                height: 300px;
            }

            &.rtl {
                img {
                    position: absolute;
                    right: -20px;

                }
            }

            &.ltr {
                img {
                    position: absolute;
                    left: -20px;
                }
            }
        }
    }

    @include for-normal-layout {
        .intro-text {
            width: rem(400px);
        }
    }

    @include for-wide-layout {
        justify-content: center;
        gap: rem(270px);

        .intro-text {
            width: rem(500px)
        }

        .decoration {
            .decoration-color {
                height: 450px;
                width: 180px;
            }

            img {
                width: 250px;
                height: 380px;
            }

            &.rtl {
                img {
                    right: -75px;
                }
            }

            &.ltr {
                img {
                    left: -75px;
                }
            }
        }
    }
}

.pages-button-wrapper {
    text-align: center;

    button {
        @include button;
        margin: 0 rem(10px);

    }

    margin: rem(20px) 0;
}